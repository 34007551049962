// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* This will remove the outline for mouse users but keep it for keyboard users */
.using-mouse :focus {
  outline: none;
}

h5 {
  margin: 20px;
  text-align: center;
}

.imageListItem {
  transition:  background-color 0.5s ease; /* Smooth transition for background-color */
}

.imageListItem:hover {
  cursor: pointer;
  background-color: #97B5C7 !important;
  color: white !important;
}

.featured-project video {
  width: 60% !important;
  max-height: 600px !important;
  border-radius: 10px;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .imageListItem {
    width: 80% !important;
    margin: 0 auto !important;
  }

  .featured-project video {
    width: 100% !important;
}
}`, "",{"version":3,"sources":["webpack://./src/style/ProjectList.css"],"names":[],"mappings":"AAAA;EACE;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA,gFAAgF;AAChF;EACE,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,uCAAuC,EAAE,2CAA2C;AACtF;;AAEA;EACE,eAAe;EACf,oCAAoC;EACpC,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;EACrB,4BAA4B;EAC5B,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE;IACE,qBAAqB;IACrB,yBAAyB;EAC3B;;EAEA;IACE,sBAAsB;AAC1B;AACA","sourcesContent":["* {\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n/* This will remove the outline for mouse users but keep it for keyboard users */\n.using-mouse :focus {\n  outline: none;\n}\n\nh5 {\n  margin: 20px;\n  text-align: center;\n}\n\n.imageListItem {\n  transition:  background-color 0.5s ease; /* Smooth transition for background-color */\n}\n\n.imageListItem:hover {\n  cursor: pointer;\n  background-color: #97B5C7 !important;\n  color: white !important;\n}\n\n.featured-project video {\n  width: 60% !important;\n  max-height: 600px !important;\n  border-radius: 10px;\n  cursor: pointer;\n}\n\n@media only screen and (max-width: 768px) {\n  .imageListItem {\n    width: 80% !important;\n    margin: 0 auto !important;\n  }\n\n  .featured-project video {\n    width: 100% !important;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
