// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Projects.css */
.Projects-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  font-size: calc(10px + 2vmin);
  color: #AAAAAA;
}

.Projects-link {
  color: #61dafb;
}`, "",{"version":3,"sources":["webpack://./src/style/Projects.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;EACE,uBAAuB;EACvB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,oBAAoB;EACpB,6BAA6B;EAC7B,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["/* Projects.css */\n.Projects-header {\n  background-color: white;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: top;\n  font-size: calc(10px + 2vmin);\n  color: #AAAAAA;\n}\n\n.Projects-link {\n  color: #61dafb;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
